/* Datepicker hacks */

.react-datepicker__time-container {
  width: 20vw !important;
}

.react-datepicker__time-box {
  width: 100% !important;
}

@media (max-width: 991px) {
  .react-datepicker__time-container {
    width: 50vw !important;
  }
}

@media (max-width: 575px) {
  .react-datepicker__time-container {
    width: 80vw !important;
  }
}
