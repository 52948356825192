@import url("./colors.css");

#root {
  display: flex;
  justify-content: center;
}

/* Color classes */
.blue {
  color: var(--primaryColor);
}

.green {
  color: green;
}

.grey {
  color: var(--quartenaryColor);
}

.orange {
  color: var(--secondaryColor);
}

.red {
  color: red;
}

/* My classes */

.align-center {
  display: flex;
  align-items: center;
}

.backButton {
  position: absolute;
  left: 5px;
  z-index: 10;
}

.bank-info:hover {
  color: var(--secondaryColor);
  cursor: pointer;
}

.bottom-bar {
  /* background-color: red; */
  height: 10%;
}

.counters {
  display: flex;
  float: right;
  margin-top: -10px;
  font-size: small;
}

.fake-button {
  border: 1px solid var(--primaryColor);
  border-radius: 4px;
  padding: 5px 0;
  display: flex;
  width: 120px;
  justify-content: center;
  align-items: center;
}

.fake-button:hover {
  border-color: var(--secondaryColor);
  background-color: var(--secondaryColor);
  cursor: pointer;
  color: var(--tertiaryColor);
}

.favorites {
  display: flex;
  font-size: x-small;
  width: fit-content;
}

/* .favorites-item{
 flex-direction:column
} */

.favorites:hover,
.favorites-item:hover {
  cursor: pointer;
  color: var(--secondaryColor);
}

.filterFields {
  margin-left: 0;
}

.filter-drivers-companies-buttons {
  padding: 0 10px;
  margin-bottom: 0;
  margin-left: 5px;
  flex: none;
  min-width: auto;
}

.filter-drivers-companies-fields {
  max-height: 32px;
  text-transform: capitalize;
}

.filter-drivers-companies-fields:focus {
  box-shadow: none;
  border-color: var(--secondaryColor);
}

.tab-list {
  max-height: 60%;
}

.top-bar {
  /* background-color: green; */
  height: 20%;
  flex-direction: column;
}

.main-card {
  width: 30vw;
}

.main-card-body {
  height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  justify-content: center;
}

.main-card-footer {
  background-color: transparent;
  font-size: x-large;
  z-index: 1000;
}

.main-card-header {
  background-color: transparent;
  border-bottom: none;
  padding: 5px;
  font-size: x-large;
}

.middle-bar {
  height: 70%;
  flex-direction: column;
}

.my-nav-tabs {
  max-width: max-content;
}
.nav-main,
.nav-card {
  z-index: 2000000000;
  position: fixed;
  top: 15px;
  right: 30px;
  background-color: var(--tertiaryColor);
  border-radius: 0.25rem;
  margin-bottom: 10px;
}

.nav-pages-toogle,
.nav-pages-toogle:focus,
.nav-pages-toogle:active {
  background-color: var(--primaryColor);
  margin-bottom: 0;
  color: var(--tertiaryColor);
  border-color: var(--primaryColor);
  box-shadow: none;
}

.nav-pages-show,
.nav-pages-show:focus,
.nav-pages-show:active {
  background-color: var(--tertitaryColor);
  margin-bottom: 0;
  color: var(--primaryaryColor);
  border-color: var(--primaryColor);
  box-shadow: none;
}

.nav-pages-show:hover {
  cursor: initial !important;
}

.nav-pages-toogle:hover {
  background-color: var(--secondaryColor);
  color: var(--tertiaryColor);
  border-color: var(--secondaryColor);
}

.pages-list {
  min-width: 5rem;
  max-height: 9.25rem;
  overflow: auto;
  overflow-x: hidden;
  scrollbar-width: auto;
  scrollbar-color: var(--secondaryColor) var(--tertiaryColor); /* Firefox */
}

.pages-list > a:hover {
  background-color: var(--secondaryColor);
  color: var(--tertiaryColor);
  border-right: 1px solid var(--tertiaryColor);
}

.pin:hover,
.star:hover {
  cursor: pointer;
}

/* Bootstrap hacks */
.alert {
  width: 30vw;
  margin-bottom: 10px;
}

.alert-confirm {
  max-width: fit-content;
}

.card-login {
  display: flex;
  align-items: center;
  height: 75vh;
  width: 90%;
}

.card-stats {
  display: flex;
  align-items: center;
  height: 25vh !important;
  width: 10vw;
  border: 2px solid var(--primaryColor);
  border-radius: 5px;
}

.card-stats:hover {
  cursor: pointer;
  background-color: var(--secondaryColor);
  color: var(--tertiaryColor);
}

.card-login > .card-body {
  padding: 0;
  width: 80%;
  overflow: auto;
  overflow-x: hidden;
  scrollbar-width: auto;
  scrollbar-color: var(--secondaryColor) var(--tertiaryColor); /* Firefox */
}

.card-login > .card-footer {
  background-color: var(--tertiaryColor);
  width: 100%;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.container-fluid,
.navbar {
  height: 100vh;
}

.css-1lmy46j-ClockWrapper {
  background: none !important;
  padding: 0px !important;
}

.css-xbtxlx-Meridiems,
.css-wufnqa-Meridiems {
  background: var(--primaryColor) !important;
  color: var(--tertiaryColor) !important;
}

.css-1r6tdpo-Meridiems {
  padding-top: 5px !important;
}

.css-upwegn-TopBar {
  padding: 0 16px !important;
}
.css-16j6ljo-ClockHand,
.css-1jj6dwi-ClockHand {
  fill: var(--primaryColor) !important;
}

.css-4456cn-ClockHand {
  stroke: var(--primaryColor) !important;
}

.css-1nkra40-TopBar,
.css-1aty463-TopBar {
  color: var(--primaryColor) !important;
}

.css-nakgy8-TimeKeeper {
  box-shadow: none !important;
}

.dropdown-item {
  color: var(--primaryColor);
}

.dropdown-item:active,
.dropdown-item.active {
  background-color: var(--tertiaryColor);
  color: var(--primaryColor);
}

.dropdown-item:hover {
  background-color: var(--tertiaryColor);
  color: var(--secondaryColor);
}

.errorField {
  border-color: red;
  box-shadow: 0 0 0 0.1rem rgba(220, 53, 69, 0.35);
}

.filterFields {
  padding: 0 5px;
}

.filterRows {
  margin: 10px 0 0 10px;
}

.form-control:focus {
  border-color: var(--secondaryColor);
  box-shadow: 0 0 0 0.1rem rgba(255, 165, 0, 0.35);
}

.groupButtons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.height10 {
  height: 10vh;
}

.height90 {
  height: 90vh;
}

.info-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 5px;
}

.list-element {
  margin: 5px;
  border: 1px solid var(--secondaryColor);
  border-radius: 4px;
}

.list-delivery {
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.list-group {
  width: 100%;
}

.list-item {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.list-item:focus {
  background-color: var(--primaryColor);
  color: var(--tertiaryColor);
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group-item {
  display: flex;
  padding: 10px 0;
}

.list-menu-item {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: flex-end;
  padding-left: 5px !important;
}

.list-scrollable {
  max-height: 40vh;
  overflow: auto;
  overflow-x: hidden;
  scrollbar-width: auto;
  scrollbar-color: var(--secondaryColor) var(--tertiaryColor); /* Firefox */
}

.logo {
  width: 150px;
  height: 160px;
}

.logo2 {
  width: 290px;
  height: 300px;
}

.logo3,
.logo4 {
  width: 120px;
  height: 130px;
}

.logo-geral {
  width: 70px;
  height: 80px;
}

.loading-modal {
  background-color: var(--tertiaryColor);
  opacity: 0.7;
}

.menu-item:hover {
  cursor: pointer;
  color: var(--secondaryColor) !important;
}

.min-dialog {
  width: fit-content;
}

.modal-fited .modal-content {
  width: auto;
}

.modalDialog {
  margin: 5px auto 0 auto;
}

.navbar {
  background-color: var(--tertiaryColor);
  border-right: 1.3px solid var(--quartenaryColor);
  padding: 0 15px !important;
}

.navbar2 {
  background-color: var(--primaryColor);
  color: var(--tertiaryColor);
  border-right: 2px solid var(--quartenaryColor);
  padding: 0 15px !important;
}

.nav-card {
  width: 150px;
  height: fit-content;
  right: 15px;
}

.nav-card-item {
  display: block;
  padding: 5px 10px;
}

.nav-card-item.active {
  background-color: var(--tertiaryColor);
  color: var(--primaryColor);
  border-color: rgba(0, 0, 0, 0.125);
}

.card-drivers-queue {
  z-index: 1;
  height: fit-content;
  top: 15px;
  right: 15px;
  width: 200px;
  /* width: fit-content; */
}

.container-toogle-button {
  background-color: white;
  height: 50px;
  width: 200px;
  border-radius: 50px;
  position: fixed;
  z-index: 1;
  bottom: 20px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-link.active {
  background-color: var(--primaryColor) !important;
  color: var(--tertiaryColor) !important;
}

.nice-dates-day.-highlight {
  color: var(--secondaryColor);
}

.nice-dates-day:before {
  background-color: var(--primaryColor) !important;
}

.primaryButton,
.primaryButton:active,
.primaryButton:focus {
  background-color: var(--primaryColor);
  color: var(--tertiaryColor);
  border-color: var(--primaryColor);
  min-width: 70px;
}

.primaryButton:disabled {
  background-color: var(--quartenaryColor);
  border-color: var(--quartenaryColor);
}

.page-container {
  background-color: #fcfdff;
  padding: 20px 25px;
}

.page-link {
  border: 1px solid var(--primaryColor);
  color: var(--primaryaryColor);
  font-weight: bold;
}

.page-link:hover {
  border-color: var(--secondaryColor);
  background-color: var(--secondaryColor);
  color: var(--tertiaryColor);
}

.page-link:focus,
.page-link:active {
  box-shadow: none;
}

.primaryButton:hover,
.secondaryButton:hover,
.backButton:hover {
  background-color: var(--secondaryColor);
  color: var(--tertiaryColor);
  border-color: var(--secondaryColor);
  cursor: pointer;
}

.recover:hover {
  color: var(--secondaryColor);
  cursor: pointer;
}

.route-head {
  flex-direction: column;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 5px;
  border-radius: 5px !important;
}

.route-head:hover {
  cursor: pointer;
  background-color: var(--secondaryColor);
  color: var(--tertiaryColor);
}

.primaryButton:focus,
.secondaryButton:focus,
.recover:focus {
  box-shadow: none;
}

.secondaryButton {
  background-color: var(--tertiaryColor);
  color: var(--primaryColor);
  border-color: var(--primaryColor);
  min-width: 70px;
}

.selectBox {
  float: right;
  height: 34px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.selected-navlink {
  color: var(--secondaryColor);
}

.selected-navlink:hover {
  cursor: text;
}

.payments-content .tab-content {
  border-bottom: 1px solid var(--primaryColor);
}

.trash-bin {
  margin-left: 3px;
  margin-right: 2px;
  color: red;
}

.tooltip-inner {
  color: var(--primaryColor);
  background-color: var(--tertiaryColor);
  border: 1px solid var(--primaryColor);
  opacity: 1;
}

#pending-tooltip {
  font-weight: bold;
}

#pending-tooltip > .tooltip-inner {
  color: var(--tertiaryColor);
  background-color: var(--secondaryColor);
  border: 1px solid var(--secondaryColor);
  opacity: 1;
}

#pending-tooltip > .arrow::before {
  border-right-color: var(--secondaryColor);
}

.pending-buttons-box {
  padding: 0 25px 0 0;
  flex-direction: column;
  justify-content: space-evenly;
}

.pending-buttons-box > svg:hover {
  color: var(--secondaryColor) !important;
  cursor: pointer;
}

.title {
  border-bottom: 1.2px solid #aaa;
  margin-bottom: 10px;
  width: 100%;
}

.to-right {
  display: flex;
  justify-content: flex-end;
}

tbody {
  height: 200px;
  overflow-y: auto;
  width: 100%;
}

.xablau {
  height: 14.5rem;
}

.puppetPlus {
  color: var(--secondaryColor);
}

.puppetPlus:hover {
  color: var(--tertiaryColor);
}
.credit-balance {
  color: var(--primaryaryColor);
  font-size: 16px;
  margin-top: 16px;
}

@media (max-width: 992px) {
  .logo2 {
    width: 150px;
    height: 160px;
  }

  .filterFields {
    padding: 5px 0;
  }

  .filterRows {
    flex-direction: column;
  }
}

@media (max-width: 560px) {
  .alert {
    width: fit-content;
  }

  .card {
    height: 70vh;
  }

  .logo {
    width: 75px;
    height: 80px;
  }

  .nav-main {
    z-index: 1010;
  }

  .nav-card {
    width: fit-content;
    right: 10px;
  }

  .fixed-loading {
    position: fixed;
    left: 25vh;
  }

  .card {
    z-index: 0;
  }

  .marginBetween {
    margin-top: 5px;
  }

  .selectBox {
    height: 32px;
  }
  .card-drivers-queue {
    display: none;
  }
}

@media (max-width: 359px) {
  .nav-card {
    width: fit-content;
    right: 5px;
  }
}

/* My reponsivity classes */
@media (max-width: 1199px) {
  .main-card {
    width: 50vw;
  }
}

@media (max-width: 920px) {
  .logo3,
  .logo4 {
    width: 80px;
    height: 90px;
  }
}
@media (max-width: 1024px) {
  .top-bar {
    height: 32%;
  }

  .middle-bar {
    height: 55%;
  }
  .list-scrollable {
    max-height: 25vh;
  }
}

@media (max-width: 575px) {
  .card-stats {
    width: 50vw;
  }

  .fake-button {
    width: 100px;
    margin-top: 5px;
  }

  .filter {
    margin-bottom: 10px;
  }

  .filter-drivers-companies-fields {
    max-height: 28px;
  }

  .filter-drivers-companies-buttons {
    padding: 0.375rem 0.75rem;
    margin-top: 5px;
  }

  .list-group-item {
    height: fit-content;
  }

  .main-card {
    width: 100%;
    height: fit-content;
  }

  .nav-main {
    right: 15px;
  }

  .logo3 {
    width: 70px;
    height: 80px;
  }

  .logo4 {
    width: 50px;
    height: 60px;
  }

  .main-card-footer {
    font-size: large;
  }

  .navbar-item-text {
    display: none;
  }

  .title {
    font-size: 1.25rem;
  }

  .tab-list {
    height: 50%;
  }

  .tab-list-tabs > .nav-link {
    padding: 0.4rem 0.8rem;
  }

  .top-bar {
    height: 32%;
  }

  .main-top-bar {
    height: 25%;
  }

  .middle-bar {
    height: 60%;
  }

  #onOffSelect {
    margin-top: 5px;
    padding: 0;
  }

  .btn,
  .fake-button,
  .nav-pages-show,
  .nav-pages-toogle {
    padding: 0.175rem 0.75rem !important;
  }
}

@media (max-width: 320px) {
  .tab-list-tabs > .nav-link {
    padding: 0.3rem 0.6rem;
  }

  .logo3,
  .logo-geral {
    width: 50px;
    height: 60px;
  }

  .main-card-footer {
    font-size: larger;
  }
}
