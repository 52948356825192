@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap");
@import url("./colors.css");

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

a {
  color: var(--primaryColor);
}

a:hover {
  color: var(--secondaryColor);
  text-decoration: none;
}

body {
  font: 400 14px Roboto, sans-serif;
  background-color: var(--primaryColor);
  color: var(--primaryColor);
  -webkit-font-smoothing: antialiased;
}

input,
button,
textarea {
  font: 400 18px Roboto, sans-serif;
  outline: none;
  margin-bottom: 10px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px var(--tertiaryColor) inset;
  -webkit-box-shadow: 0 0 0px 1000px var(--tertiaryColor) inset;
  -webkit-text-fill-color: var(--primaryColor);
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

select:invalid {
  color: grey;
}

/* Chrome, Safari, Edge, Opera */
::-webkit-scrollbar {
  width: 10px;
}
.withoutscroll::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--secondaryColor);
  height: 25px;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--secondaryColor);
  height: 25px;
  border-radius: 5px;
}

@media (max-width: 560px) {
  body {
    font: 400 12px Roboto, sans-serif !important;
  }

  input,
  button,
  textarea {
    font: 400 14px Roboto, sans-serif !important;
  }
}
