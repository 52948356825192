.messager{
  width: fit-content;
  text-align: center;
  position: absolute !important;
  top:10px;
  z-index: 1000000;
}

.login{
  margin-left: 15%;
}